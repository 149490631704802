import { css } from '@emotion/react'
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';

// FINANCIERA COMPONENTS
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'
//MATERIAL UI COMPONENTS 
import {Button, Snackbar, Grid, Box } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

// COMPONENTES PROPIOS DEL SYSTEM
import Signature from '../../Signature/Signature';
import { navigate } from "../../../helpers/queryNavigate"
import { getPathById } from "../../../helpers/array"

//MATERIAL ICONS
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

//SERVICES FOR BFF 
import { PrescoreService } from '../../../services/LandingV4/prescore'
import DataUrlToFile from '../../../helpers/dataUrlToFile'
import { withThemeProps } from '@findep/microfronts-core';
import { restoreFromSessionStorage } from '../../../components/pages/afi/restoreSessionStorage';
import { CataloguesService } from '../../../services/LandingV4'
import { ScoreServiceSoft } from '../../../services/LandingV4/scoreSoft'
// import axios from 'axios';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { CheckBox } from '@material-ui/icons';
import CheckboxGrl from '../CheckboxGrl';
import DialogGeneric from '../DialogGeneric';

const preKba = new PrescoreService();
const catalogues = new CataloguesService();

const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const mb = css`
  margin-bottom: 1rem;
`

const mx = css`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const root = css`
  padding-left: 1rem;
  padding-right: 1rem;
`
const float = css`
  float:rigth;
  position:fixed;
`

class ValidacionIdentidad extends Component {

  constructor(props) {
    super(props)
    this.state = {
      flujo: restoreFromSessionStorage('flujo'),
      signature: '',
      openBackDrop: false,
      errorService: false,
      btnSubmit: false,
      bdHabilita: false,
      actRadio: false,
      legales: '',
      soft_active:false,
      check_buro: false,
      check_declaracion:false,
      next_firma:false, 
      dialog_active:false , 
      view:true,  
    }
    // console.log("pageContext s",this.props.pageContext);
  }

  async caceledLead(creditApplicationId){
    await catalogues.putCanceledLead(creditApplicationId)
       .then((response) => {
           console.log("cancelLead=>",response.data)
       })
       .catch(error => {
         this.setState({ errorService: true })
         console.error(error)
       })
  }

  async ficoPass(){    
    let flujo = JSON.parse(sessionStorage.getItem('flujo'))
    const scoreSrv = new ScoreServiceSoft(flujo.creditApplicationId)
    let navigateUrl = this.props.pageContext?.next?.path
    let zipcode
    let sucursalId

    await catalogues.cavSectionGeneric(flujo?.creditApplicationId,"person.address[0]")
    .then((response)=>{
      console.log("cavSection=>",response.data.postalCode)
      zipcode=response.data.postalCode
    })
    await catalogues.getNeighborhoodsAfi(zipcode)
    .then((response) => {
      console.log("sucursalId=>",response.data.neighborhoods[0].sucursalId)
      sucursalId=response.data.neighborhoods[0].sucursalId
    })

    // if(sucursalId===610){
      // console.log("flujo->soft")
    await  scoreSrv.sendScoreSoft()
      .then((response) => {          
         console.log("score/soft=>",response)
         if (response.data.enproceso) {    
            navigateUrl = getPathById(this.props.pageContext.errorPath, 'solicitud-existe')            
            navigate(navigateUrl) 
         }else{
          if (response.data.canContinue) {  
            window.dataLayer.push({'prescore': response?.data?.canContinue});                      
            sessionStorage.setItem("amount-soft",JSON.stringify(response.data.amount))                        
            navigateUrl = getPathById(this.props.pageContext.errorPath, 'ficopass')
            navigate(navigateUrl)            
          }else{
            window.dataLayer.push({'prescore': response?.data?.canContinue});
            navigateUrl = getPathById(this.props.pageContext.errorPath, 'lo-sentimos-fico')            
            navigate(navigateUrl)            
          }          
         }
          
        })
        .catch(error => {
          console.log("error", error);
          this.setState({ errorService: true, bdHabilita: false })
        })
    // }else{
    //   // this.submitStep()
    //   navigate(navigateUrl)    
    // }
    localStorage.clear();
  }

  async submitStep() {
    this.setState({ btnSubmit: false, bdHabilita: true })
    setTimeout(()=>{this.setState({ firmar_solicitud: false })},3000);
    let flujo = restoreFromSessionStorage('flujo')
    const url = window && document.location.search
    const urlParams = new URLSearchParams(url);
    const id = urlParams.get('creditApplicationId');
    if (id) {
      flujo = { ...flujo, creditApplicationId: id }
      sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }

    const prescore = new PrescoreService(flujo.creditApplicationId)
    let file = await DataUrlToFile(this.state.signature, `${flujo.creditApplicationId}.png`)    
    prescore.setSignature(file)
    try {
      const { data } = await prescore.send()
      
      if (data.approved) {        
        sessionStorage.setItem('bcScore', data.bcScore)
        //CREATE SERVICE POLICES AFI
      catalogues.putPolicesAfi(flujo.creditApplicationId)
      .then((response) => {
          console.log("srvPolices=>",response.data?.canContinue)
          if(response.data?.canContinue){
            // this.postAutUrl()     
            // navigate(this.props.pageContext.next.path)    
            this.ficoPass()         
          }else{
            this.caceledLead(flujo.creditApplicationId)
             navigate(getPathById(this.props.pageContext.errorPath, 'legacy'))
          }
          
      })
      .catch(error => {
        this.setState({ errorService: true })
        console.error(error)
      })
       
      } else {
        // Enviar a pantalla lo-sentimos
        // console.log("else", data)
        navigate(this.props.pageContext.errorPath)
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ errorService: true, btnSubmit: true, bdHabilita: false })

    }
  }

  //FIRST SERVICE CONSULTING TO BFF
  async prescoreKbaService() {    
    const flujo = restoreFromSessionStorage('flujo')
    preKba.prescoreSinKba(flujo.creditApplicationId)
      .then((response) => {
        // console.log(response)
        const { forceRedirect } = response.data
        if (forceRedirect !== null && forceRedirect !== undefined) {
          navigate(forceRedirect)
        }
        if (response.status === 200) {
          this.setState({ legales: response.data.legalCaption });
          this.setState({ bdHabilita: false })
        } else {
          // console.log("Fail->", response)
          this.setState({ errorService: true, bdHabilita: false })
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ errorService: true, bdHabilita: false })
      })
  }

  // componentDidMount(){
  //   const response = axios.get('https://www.cloudflare.com/cdn-cgi/trace')
  //   .then((res) => {
  //       // console.log("response ", res)
  //       let data = res.data.trim().split('\n').reduce(function (obj, pair) {
  //           pair = pair.split('=');
  //           return obj[pair[0]] = pair[1], obj;
  //       }, {});
  //       // console.log("data=>", data.ip);
  //       sessionStorage.setItem("ip", data.ip);
  //   })
  // }

 
  async componentDidMount() {
    let estado= await JSON.parse(sessionStorage.getItem('afiStateShort')) || 'CA';
    if(estado ==='TX'){      
        this.setState({
            view: false,
            check_declaracion:true
        })
    }
    // console.log("componentDidMount",estado);
    this.prescoreKbaService();
    
  }


  //VERIFICA SI SE CUMPLEN TODAS LA CONDICIONES PARA ENVIAR LA INFORMACION
  componentDidUpdate(prevProp, prevState) {
    const {
      signature,
      check_buro,
      check_declaracion
    } = this.state
    if (
      signature !== ''

    ) {
      if (!prevState.btnSubmit) {
        this.setState({ btnSubmit: true })
      }
    } else if (prevState.btnSubmit) {
      this.setState({ btnSubmit: false })
    }

    if(
      check_buro  &&
      check_declaracion
    ){
      if (!prevState.next_firma) {
        this.setState({ next_firma: true })
      }
     }else if (prevState.next_firma) {
      this.setState({ next_firma: false })
    }

  }

  checkValue(e,value){
    // console.log("check=>",value,e)
    this.setState({[e]:value})
    if(e==="check_declaracion" && value ){
      this.setState({dialog_active:true})
    }
  }

  render() {
    const { soft_active} = this.state
    const { classes } = this.props;

    const {
      titleIconCredito,
      boxMessage1,
      boxMessage2,
      boxMessage3,
      boxMessage4,
      buttonDegradeContinue,
      alertMessage,
      lblFirmarSolicitud,
      checkBuroTxt,
      checkDeclaracionTxt
    } = this.props
    
    const modalSignature=    
                  <Grid container>
                    <Grid item xs={12}>
                    <Box>
                      <Signature onChange={e => { this.setState({ signature: e.value }) }} />
                    </Box>
                    </Grid>
                    <br/>&nbsp;
                    <Grid container justify="center">
                      <Grid item>
                        <div css={buttonCss} >
                        <Button onClick={() => this.submitStep()} color="primary" variant="contained" disabled={!this.state.btnSubmit}>
                          {buttonDegradeContinue}
                        </Button>                          
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                
    
    return (
      <Grid container justify="center">
        <span css={float}>{this.state.y}</span>
        <Grid item xs={12} md={6} css={root}>
          <Grid container justify="center">
            <Box css={mx}>
              <TitleIcon title={titleIconCredito} icon={ThumbUpAltOutlinedIcon} textColor={true} />
              
            </Box>
          </Grid>
          <CardElevation >
            <Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    <Box css={mb}>
                    {soft_active ? boxMessage3 : boxMessage1}
                    </Box>                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CheckboxGrl  
                    onChange={(e,info)=>{this.checkValue(e,info)}}
                    title={checkBuroTxt}
                    alias="check_buro"     
                    disabled={false}               
                  />                  
                </Grid>  
                {this.state.view &&
                  <Grid item xs={12}>
                    <CheckboxGrl  
                      onChange={(e,info)=>{this.checkValue(e,info)}}
                      title={checkDeclaracionTxt}
                      alias="check_declaracion"
                      disabled={!this.state.check_buro}                    
                    />                  
                  </Grid>
                }              
                
                {this.state.next_firma &&
                  <Grid item xs={12}>
                    <Typography variant="body2" component="div">                    
                      <Box css={mb}>
                      {soft_active ? boxMessage4 : boxMessage2}
                      </Box>
                    </Typography>
                  </Grid>
                }

                &nbsp;<br />
                <Grid container justify="center">

                  <Grid item>
                    <div css={buttonCss} >
                      <ButtonDegrade
                        id="firma-continuar-solicitud-button"
                        icon={ArrowForwardIcon}
                        textButton={lblFirmarSolicitud}
                        onClick={() =>{
                              this.state.view ? this.setState({ firmar_solicitud:true }) : this.setState({ firmar_solicitud:true })
                            }                         
                            }
                        disabled={!this.state.next_firma}                        
                      />
                    </div>
                  </Grid>
                </Grid>

              </Grid>
            </Fragment>
          </CardElevation>
          &nbsp;<br />&nbsp;
        </Grid>

        <DialogGeneric
            open={this.state.dialog_active}
            changueTask={(e)=>{this.setState({dialog_active:false,next_firma: true})}} 
            lang={this.props.pageContext.locale}           
        />  

        <Backdrop open={this.state.bdHabilita} className={classes.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {alertMessage}
          </Alert>
        </Snackbar>

        <Dialog                
          open={this.state.firmar_solicitud}                
          id='dialogo-sign-your-application'                
          >
          <DialogContent >
              {modalSignature}
          </DialogContent>                    
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(withThemeProps(ValidacionIdentidad, 'VDPNValidacionIdentidadAfi'));


ValidacionIdentidad.propTypes = {
  titleIconCredito: PropTypes.string,
  boxMessage1: PropTypes.string,
  boxMessage2: PropTypes.string,
  boxMessage3: PropTypes.string,
  boxMessage4: PropTypes.string,
  buttonDegradeContinue: PropTypes.string,
  alertMessage: PropTypes.string,
  lblFirmarSolicitud:PropTypes.string,
  checkBuroTxt:PropTypes.string,
  checkDeclaracionTxt:PropTypes.string
};

ValidacionIdentidad.defaultProps = {
  titleIconCredito: "Verificación de crédito",
  boxMessage1: "Con tu firma, nos autorizas obtener informes de tu historial de crédito, empleo y domicilio relacionados con tu solicitud de crédito, de conformidad con la ley aplicable y nuestra política de privacidad.",
  boxMessage2: <strong>A continuación te pedimos que firmes tal como aparece en tu identificación en el siguiente recuadro:</strong>,  
  boxMessage3: "Con su firma, usted autoriza a Apoyo Financiero, Inc. a realizar verificaciones iniciales de su historial crediticio, empleo y dirección relacionados con su solicitud de crédito, de acuerdo con la ley aplicable y nuestro proceso de evaluación crediticia, primero realizaremos una verificación inicial (verificación crediticia suave) para precalificar su solicitud. Esta verificación no afectará su FICO Score. Si cumple con nuestros criterios iniciales, procederemos con una evaluación más detallada, incluida una consulta exhaustiva que puede afectar su puntaje FICO Score.",
  boxMessage4: <strong>Por favor firme como aparece en su identificación en el cuadro a continuación:</strong>,
  buttonDegradeContinue: "Continuar con solicitud",
  alertMessage: "Lo sentimos, por el momento el servicio no se encuentra disponible",
  lblFirmarSolicitud:"Firmar tu solicitud",
  checkBuroTxt:"Autorizo la revisión de mi buró de crédito",
  checkDeclaracionTxt:"He leído, entendido el contenido, aceptado y firmado la declaración."
}